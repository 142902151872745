import './OurTeam.scss'
import React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { Link } from 'gatsby'
import { MainVariant } from 'tuix-webcomponents'

import { TuixButtonComponent } from '../../components/TuixWebcomponents/TuixButton.wrapped'

type Props = {
    ourTeamImageUrl: string
    ourTeamImageUrl2: string
    textButton1: string
    linkTo: string
}

export default ({ ourTeamImageUrl, ourTeamImageUrl2, textButton1, linkTo }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    return (
        <div className="container-our-team">
            <img src={ourTeamImageUrl} className="ourTeamImage" alt="our-team" />
            <div className="ourTeamImage2Container">
                <img src={ourTeamImageUrl2} className="ourTeamImage2" alt="our-team-location" />
            </div>
            <div className="button">
                <Link to={linkTo}>
                    <TuixButtonComponent variant={MainVariant.PRIMARY}>
                        {textButton1}
                    </TuixButtonComponent>
                </Link>
            </div>
        </div>
    )
}
