import Page from 'components/Page'

import { Locale } from '../../utils/enums'

import React from 'react'

import './index.scss'
import { usePageContent } from 'components/hooks/usePageContent/aboutUs/usePageContent'
import ImageSwitcher from 'components/ImageSwitcher'
import ImageWithText from 'components/ImageWithText'
import { ImageWithTextModel } from 'components/ImageWithText/ImageWithText'
import { LogoTypeModel } from 'components/Logotype/Logotype'
import LogotypeContainer from 'components/LogotypeContainer/LogotypeContainer'
import MainImage from 'components/MainImage'
import { MainImageModel } from 'components/MainImage/MainImage'
import OurTeam from 'components/OurTeam'
import Section from 'components/Section'
import { SectionModel } from 'components/Section/Section'
import { MetaData } from 'components/Seo'
import { MainVariant } from 'tuix-webcomponents'

import { getDataByLocale, mappingEdge } from '../../utils/dataHelpers'
import { getLanguageByLocale } from '../../utils/language'
import { Asset } from '../../utils/types'

interface Props {
    locale: Locale
}
interface TextButtonModel {
    text: string
    node_locale: Locale
}

export default ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const {
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesSection3,
        nodesSection4,
        nodesImageWithTextEdges,
        nodesLogotypeEdges,
        nodesTextButton1,
        nodesOurTeamImage,
        nodesOurTeamImage2,
        nodesLanguagesImage,
        nodesLanguagesImageDark,
        nodesMetaData,
    } = usePageContent()

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    const {
        backgroundImage: {
            file: { url },
        },
        backgroundImageDark: {
            file: { url: imageUrlDark },
        },
        title,
        textVariantTitle,
        subtitle,
        textVariantSubtitle,
        textPosition,
        buttonText,
        isStaticText,
    } = getDataByLocale<MainImageModel>(nodesMainImage, locale)

    // const { isDarkMode } = useDarkModeContext()
    const { title: section1Title } = getDataByLocale<SectionModel>(nodesSection1, locale)
    const { title: section2Title } = getDataByLocale<SectionModel>(nodesSection2, locale)
    const { title: section3Title } = getDataByLocale<SectionModel>(nodesSection3, locale)
    const { title: section4Title } = getDataByLocale<SectionModel>(nodesSection4, locale)
    const imagesWithText = mappingEdge<ImageWithTextModel>(nodesImageWithTextEdges, locale)
    const logotypes = mappingEdge<LogoTypeModel>(nodesLogotypeEdges, locale)
    const { text: textButton1 } = getDataByLocale<TextButtonModel>(nodesTextButton1, locale)
    const {
        file: { url: ourTeamImageUrl },
    } = getDataByLocale<Asset>(nodesOurTeamImage, locale)
    const {
        file: { url: ourTeamImageUrl2 },
    } = getDataByLocale<Asset>(nodesOurTeamImage2, locale)
    const {
        file: { url: languagesImageUrl },
    } = getDataByLocale<Asset>(nodesLanguagesImage, locale)
    const {
        file: { url: languagesImageDarkUrl },
    } = getDataByLocale<Asset>(nodesLanguagesImageDark, locale)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section id="about-us-main-image" fadein={false}>
                <MainImage
                    imageURL={url}
                    imageURLDark={imageUrlDark}
                    title={title}
                    textVariantTitle={textVariantTitle}
                    subtitle={subtitle}
                    textVariantSubtitle={textVariantSubtitle}
                    textPosition={textPosition}
                    buttonText={buttonText}
                    isStaticText={isStaticText}
                />
            </Section>
            <Section title={section1Title} id="culture-and-values">
                <div className="container-culture-and-values">
                    {imagesWithText.map((textWithImage) => (
                        <ImageWithText
                            key={textWithImage.title}
                            imageURL={textWithImage.image.file.url}
                            title={textWithImage.title}
                            paragraph={textWithImage.paragraph.internal.content}
                            imageURLDark={textWithImage.imageDark.file.url}
                        />
                    ))}
                </div>
            </Section>
            <Section backgroundColor={MainVariant.SECONDARY} title={section2Title} id="languages">
                <LogotypeContainer logos={logotypes} />
            </Section>
            <Section title={section3Title} className="sectionTechnologies">
                <ImageSwitcher
                    darkImageURL={languagesImageDarkUrl}
                    lightImageURL={languagesImageUrl}
                    className="languagesImage"
                    alt="technologies"
                />
            </Section>
            <Section
                title={section4Title}
                className="section-our-team"
                backgroundColor={MainVariant.SECONDARY}
            >
                <OurTeam
                    ourTeamImageUrl={ourTeamImageUrl}
                    ourTeamImageUrl2={ourTeamImageUrl2}
                    linkTo={`/${getLanguageByLocale(locale)}/career`}
                    textButton1={textButton1}
                />
            </Section>
        </Page>
    )
}
