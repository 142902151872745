import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        mainImage: { nodes: nodesMainImage },
        section1: { nodes: nodesSection1 },
        section2: { nodes: nodesSection2 },
        section3: { nodes: nodesSection3 },
        section4: { nodes: nodesSection4 },
        allContentfulImageWithText: { edges: nodesImageWithTextEdges },
        allContentfulLogotype: { edges: nodesLogotypeEdges },
        textButton1: { nodes: nodesTextButton1 },
        ourTeamImage: { nodes: nodesOurTeamImage },
        ourTeamImage2: { nodes: nodesOurTeamImage2 },
        languagesImage: { nodes: nodesLanguagesImage },
        languagesImageDark: { nodes: nodesLanguagesImageDark },
        allContentfulSeo: { nodes: nodesMetaData },
    } = useStaticQuery(graphql`
        query QueryAboutUs {
            mainImage: allContentfulMainImage(
                filter: { contentful_id: { eq: "6upYQVlLYO9XkyeRicX52a" } }
            ) {
                nodes {
                    subtitle
                    textVariantSubtitle
                    title
                    textVariantTitle
                    node_locale
                    textPosition
                    fontColor
                    buttonText
                    backgroundImage {
                        title
                        file {
                            url
                        }
                    }
                    backgroundImageDark {
                        title
                        file {
                            url
                        }
                    }
                    isStaticText
                }
            }
            section1: allContentfulSection(
                filter: { contentful_id: { eq: "71pEGaYvzHEhcoegmiuSh4" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }
            section2: allContentfulSection(
                filter: { contentful_id: { eq: "8zDYela6FlSW32IiWuP97" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }
            section3: allContentfulSection(
                filter: { contentful_id: { eq: "66CzEgJiZyPcyNCqT1Itih" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }
            section4: allContentfulSection(
                filter: { contentful_id: { eq: "57AshGsnwZrJx2JnIabogk" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }
            allContentfulImageWithText(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        title
                        image {
                            file {
                                url
                            }
                        }
                        imageDark {
                            file {
                                url
                            }
                        }
                        paragraph {
                            internal {
                                content
                            }
                        }
                        order
                        node_locale
                    }
                }
            }
            allContentfulLogotype(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        name
                        image {
                            file {
                                url
                            }
                        }
                        order
                        node_locale
                    }
                }
            }
            textButton1: allContentfulTextButton(
                filter: { contentful_id: { eq: "7VwVuB0dNbhPh57yAVx9o" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    text
                }
            }

            ourTeamImage: allContentfulAsset(
                filter: { contentful_id: { eq: "6EANGEYtuVCZYiZmrztFI" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }
            ourTeamImage2: allContentfulAsset(
                filter: { contentful_id: { eq: "5fGMCTIZthCl1AxwYDyyxA" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }
            languagesImage: allContentfulAsset(
                filter: { contentful_id: { eq: "4Ygey6i81ZcJTFRnlPJjNb" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }

            languagesImageDark: allContentfulAsset(
                filter: { contentful_id: { eq: "3JshAoUY54cfIuLjlH5J9x" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "6a7Tb0y2laTgGgBeayvibl" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    image {
                        file {
                            url
                        }
                    }
                    node_locale
                }
            }
        }
    `)

    return {
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesSection3,
        nodesSection4,
        nodesImageWithTextEdges,
        nodesLogotypeEdges,
        nodesTextButton1,
        nodesOurTeamImage,
        nodesOurTeamImage2,
        nodesLanguagesImage,
        nodesLanguagesImageDark,
        nodesMetaData,
    }
}
