import React from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize, TextVariant } from 'tuix-webcomponents'

import { Locale } from '../../utils/enums'
import './Logotype.scss'

type Props = {
    name: string
    imageURL: string
    customClassName?: string
}

interface File {
    url: string
}

interface Image {
    file: File
}

export interface LogoTypeModel {
    name: string
    image: Image
    order: number
    node_locale: Locale
}

const getColor = (isTablet: boolean, isMobile: boolean, isDarkMode: boolean): TextVariant => {
    return isMobile || isTablet
        ? TextVariant.PRIMARY
        : isDarkMode
        ? TextVariant.MAIN
        : TextVariant.DARK_MAIN
}

export default ({ name, imageURL, customClassName = '' }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isTablet, isMobile } = useDeviceWidth()
    const customBorderClass: string = isDarkMode ? 'custom-border-dark' : 'custom-border'
    return (
        <div className={clsx('logotype_container', customClassName, customBorderClass)}>
            <div className="img_container">
                <img src={imageURL} className="logotype_img" alt="logo type image" />
            </div>
            <TuixTextComponent
                size={isMobile ? TextSize.XL : TextSize.M}
                color={getColor(isTablet, isMobile, isDarkMode)}
                dark={isDarkMode}
            >
                {name}
            </TuixTextComponent>
        </div>
    )
}
